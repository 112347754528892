import ImageServiceClient, { Fit } from '@seneca/image-service-client'

const client = new ImageServiceClient(() => {}, {
  protocol: 'https',
  host: 'image.app.senecalearning.com',
  cdnBaseUrl: 'https://image-v2.cdn.app.senecalearning.com',
})

export default client

export const params = width => ({
  width,
  fit: Fit.INSIDE,
})
