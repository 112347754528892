// @flow
import React from 'react'

import client, { params } from 'src/common/services/imageService'

import styles from './ImageContent.module.scss'

type Props = {|
  src: string,
  alt: string,
  widths: {|
    mobile?: number,
    tablet?: number,
    desktop: number,
  |},
  onLoad: () => void,
|}

export default function ImageContent({ widths, src, alt, onLoad }: Props) {
  return (
    <picture onLoad={onLoad} className={styles.wrapper}>
      {widths.mobile && (
        <source
          srcSet={client.getImageUrl(src, params(widths.mobile))}
          media="(max-width: 600px)"
        />
      )}
      {widths.tablet && (
        <source
          srcSet={client.getImageUrl(src, params(widths.tablet))}
          media="(max-width: 1000px)"
        />
      )}
      <img
        src={client.getImageUrl(src, params(widths.desktop))}
        className={styles.image}
        alt={alt}
      />
    </picture>
  )
}
