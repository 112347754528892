// @flow
import React, { useState } from 'react'
import LazyLoad from 'react-lazyload'

import ImagePlaceholder from './ImagePlaceholder'
import ImageContent from './ImageContent'
import styles from './Image.module.scss'

type Props = {|
  src: string,
  alt: string,
  widths: {|
    mobile?: number,
    tablet?: number,
    desktop: number,
  |},
  initialAspectRatio?: number,
|}

const INITIAL_ASPECT_RATIO = 0.5

export default function Image({ widths, src, alt, initialAspectRatio }: Props) {
  const [loaded, setLoaded] = useState(false)
  const [aspectRatio, setAspectRatio] = useState(
    initialAspectRatio || INITIAL_ASPECT_RATIO
  )

  const updateAspectRatio = ({ target: img }) => {
    setAspectRatio(img.naturalHeight / img.naturalWidth)
  }

  const handleImageLoad = () => {
    setLoaded(true)
  }

  return (
    <div
      className={styles.wrapper}
      style={{
        paddingBottom: `${aspectRatio * 100}%`,
      }}
    >
      <LazyLoad className={styles.image} debounce={300} offset={300}>
        <ImageContent
          widths={widths}
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
        />
      </LazyLoad>
      <ImagePlaceholder
        src={src}
        alt={alt}
        loaded={loaded}
        onLoad={updateAspectRatio}
      />
    </div>
  )
}
