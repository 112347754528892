// @flow
import React from 'react'
import classnames from 'classnames'

import client, { params } from 'src/common/services/imageService'

import styles from './ImagePlaceholder.module.scss'

type Props = {|
  src: string,
  alt: string,
  loaded: boolean,
  onLoad: (SyntheticEvent<HTMLInputElement>) => void,
|}

const PLACEHOLDER_WIDTH = 50

export default function ImagePlaceholder({ loaded, src, alt, onLoad }: Props) {
  return (
    <img
      alt={alt}
      onLoad={onLoad}
      src={client.getImageUrl(src, params(PLACEHOLDER_WIDTH))}
      className={classnames(styles.placeholder, {
        [styles.loaded]: loaded,
      })}
    />
  )
}
